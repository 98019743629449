<template>
  <div>
    <v-row align="center">
      <v-col cols="auto">
        <h1 class="headline font-weight-bold">
          {{ id == "default" ? $t("favoritesPage.default") : collection.name }}
        </h1>
      </v-col>
      <v-col>
        <v-menu offset-y min-width="100">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :disabled="id == 'default'"
              style="letter-spacing: 0"
              @click="showCreateDialog = true"
              class="text-capitalize"
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-avatar color="grey lighten-2" size="30">
                <v-icon size="20" color="black">mdi-dots-horizontal</v-icon>
              </v-avatar>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="showEditDialog = true">
              <v-list-item-title>{{ $t("favoritesPage.edit") }} </v-list-item-title>
            </v-list-item>
            <v-list-item @click="showDeleteDialog = true">
              <v-list-item-title>{{ $t("favoritesPage.delete") }} </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <div v-if="!itemsLoading && items.length > 0">
      <v-container style="padding-left: 0">
        <v-layout row wrap>
          <v-container>
            <v-row>
              <v-col v-for="item in items" :key="item.id" cols="12" sm="4" md="3" lg="3">
                <item-card :item="item" />
              </v-col>
            </v-row>
          </v-container>
        </v-layout>
      </v-container>
      <div class="text-center pt-2">
        <v-pagination
          v-model="page"
          :length="meta.lastPage"
          @input="getItems(true)"
          color="black"
        ></v-pagination>
      </div>
    </div>
    <!-- Loader -->
    <div v-if="itemsLoading" class="mt-16 pt-16">
      <v-row
        ><v-progress-circular
          indeterminate
          class="mx-auto"
          size="100"
        ></v-progress-circular
      ></v-row>
    </div>
    <!-- No content -->
    <no-content
      v-if="!itemsLoading && items.length == 0"
      :title="$t('noContent.title')"
      :msg="$t('noContent.favorites')"
      icon="mdi-robot-confused"
    />

    <EditDialog
      v-if="showEditDialog"
      :dialog="showEditDialog"
      :collection="collection"
      @close="showEditDialog = false"
      @done="getCollection"
    />
    <DeleteDialog
      v-if="showDeleteDialog"
      :dialog="showDeleteDialog"
      :collection="collection"
      @close="showDeleteDialog = false"
      @done="$router.push('/favorites')"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Collection",
  props: ["id"],
  components: {
    ItemCard: () => import("./ItemCard.vue"),
    EditDialog: () => import("./EditDialog"),
    DeleteDialog: () => import("./DeleteDialog"),
    NoContent: () => import("../../../components/NoContent.vue"),
  },
  data() {
    return {
      itemsLoading: false,
      showEditDialog: false,
      showDeleteDialog: false,
      page: 1,
    };
  },
  computed: {
    ...mapState({
      items: (state) => state.favoritesModule.items,
      collection: (state) => state.favoritesModule.collection,
      meta: (state) => state.favoritesModule.meta,
    }),
  },
  created() {
    this.getItems(false);
    if (this.id != "default") this.getCollection();
  },
  methods: {
    getCollection() {
      this.showEditDialog = false;
      this.$store
        .dispatch("favoritesModule/getCollection", {
          id: this.id,
        })
        .catch(() => {
          this.$buefy.toast.open({
            message: this.$t("app.errorNotif"),
            type: "is-danger",
          });
        });
    },
    getItems(paginating) {
      // this.showCreateDialog = false;
      if (paginating !== true) {
        this.page = 1;
      }
      this.itemsLoading = true;
      this.$store
        .dispatch("favoritesModule/getCollectionItems", {
          filters: {
            page: this.page,
            container_id: this.id != "default" ? this.id : null,
          },
        })
        .catch(() => {
          this.$buefy.toast.open({
            message: this.$t("app.errorNotif"),
            type: "is-danger",
          });
        })
        .finally(() => {
          this.itemsLoading = false;
        });
    },
  },
};
</script>
